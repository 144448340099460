import axios from "axios";

interface RefinanceSessionCallBacksRequestDto {
  employmentStatus?: string
  phoneNumber: string
  utmSource?: string
  utmCampaign?: string
  utmMedium?: string
}

export const requestSaveCallbackData =
  (values: RefinanceSessionCallBacksRequestDto) =>
    axios.post("/refinance/api/openRoute?path=refi/session-call-back", values);
