import Link from "next/link";
import React, { useState } from "react";
import { NewSingleSelect } from "components/Inputs/MultiSelect";
import PhoneInputWithFlagV2 from "components/Inputs/PhoneInputWithFlagV2";
import { landingPageEmploymentStatusOptions } from "DataMapper/Refi/SelectOptions";
import { Field, Form } from "react-final-form";
import { trackClick, trackForm } from "scripts/segment";
import { pageName } from "utils/events_utils";
import { getUtmData } from "utils/utm_utils";
import { companyContacts } from "lib/constants/companyContacts";
import { requestSaveCallbackData } from "components/LandingPage/LandingPageApi";

function GetCallbackForm({ showEmploymentStatusField = true }) {
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const countries: Array<{ name: string; code: string }> = [
    { name: "us", code: "🇺🇸 +1" },
    { name: "indian", code: "🇮🇳 +91" },
  ];

  const saveCallbackData = async (values: Record<string, any>) => {
    try {
      const { employmentStatus, phone: { phoneNumber, countryCode } } = values || {};
      const { utmSource, utmCampaign, utmMedium } = getUtmData(null);
      const res = await requestSaveCallbackData(
        {
          employmentStatus,
          phoneNumber: countryCode + phoneNumber,
          utmSource,
          utmCampaign,
          utmMedium,
        }
      );
      if (res?.data?.success) {
        trackForm("Form Filled", {
          product: "Refi",
          employmentStatus: values?.employmentStatus,
          phoneNumber: `${values?.phone?.countryCode}${values?.phone?.phoneNumber}`,
        });
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };
  return (
    <Form
      initialValues={
        showEmploymentStatusField
          ? { employmentStatus: landingPageEmploymentStatusOptions[0].value }
          : {}
      }
      onSubmit={saveCallbackData}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <fieldset>
            {showEmploymentStatusField && (
              <div>
                <div className="w-full pb-[10px]">
                  <Field
                    name="employmentStatus"
                    component={NewSingleSelect}
                    options={landingPageEmploymentStatusOptions}
                    label="I am a"
                    validate={(v) => {
                      if (!v) return "Please select your employment status";
                      return null;
                    }}
                  />
                </div>
              </div>
            )}
            <div className="w-full pb-4">
              <Field
                name="phone"
                component={PhoneInputWithFlagV2}
                options={countries}
                placeholder="Mobile Number"
                label="Mobile Number"
                validate={(v) => {
                  if (!v) return "Please enter your contact number";
                  if (v?.phoneNumber?.length !== 10) {
                    return "Please enter a valid phone number.";
                  }
                  return null;
                }}
              />
            </div>
            <button
              type="submit"
              onClick={() => {
                trackClick(pageName.REFI_LANDING_PAGE, {
                  widgetName: showEmploymentStatusField
                    ? "Hero"
                    : "We're here for you",
                  widgetFormat: "Form",
                  contentName: "Get a Callback",
                  contentFormat: "Button",
                });
                return handleSubmit;
              }}
              className="bg-primary leading-none text-white border border-primary w-full font-bold text-center shadow px-4 py-[18px]"
            >
              {submitting ? "Requesting..." : "Get a Callback"}
            </button>
            {isSuccess === false && (
              <p className="text-[14px] text-[#1B1D6A] bg-[#FFDEDE] leading-5 my-3 rounded p-2">
                Oops! Something went wrong. Alternatively, you can ping us
                on&nbsp;
                <Link
                  href={companyContacts.link.whatsApp}
                  className="cursor-pointer underline underline-offset-4"
                  target="_blank"
                >
                  WhatsApp
                </Link>
              </p>
            )}
            {isSuccess === true && (
              <p className="text-[14px] text-[#FFFFFF] bg-[#443EFF] leading-5 my-3 rounded p-2">
                Your request has been submitted! <br />
                While you wait, click here to check your&nbsp;
                <Link
                  href="#my-eligibility-id"
                  className="cursor-pointer underline underline-offset-4"
                >
                  Eligibility
                </Link>
              </p>
            )}
            {showEmploymentStatusField && (
              <p className="text-[15px] text-[#484A4D] leading-5 my-3">
                Our loans expert will call you to explain the refinancing
                process.
              </p>
            )}
          </fieldset>
        </form>
      )}
    </Form>
  );
}

export default GetCallbackForm;
