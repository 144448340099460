import { handleKeyPressForNumericInputs } from "lib/helpers/validations";
import { FunctionComponent, useState, WheelEvent } from "react";
import { FieldRenderProps } from "react-final-form";

const PhoneInputWithFlagV2: FunctionComponent<
FieldRenderProps<any | number, HTMLElement>
> = ({
  input,
  meta,
  label,
  disabled,
  placeholder,
  maxLength,
  icon,
  helperText,
  options,
}) => {
  const [selectedCountry, setSelectedCountry] = useState("us");

  const hasError = meta && meta.touched && meta.error;
  const value = input && input.value !== "";
  const name = input && input.name;

  const { phoneNumber } = input.value || {};
  return (
    <div className="relative">
      <div className={value ? "is-filled" : ""}>
        <select
          className="absolute font-bold top-[1px] left-[1px] h-[54px] bg-[#E9E8FA] pl-4 border-r-[12px] border-transparent"
          onChange={(e) => {
            setSelectedCountry(e.target.value);
            input.onChange({
              countryCode: e.target.value === "us" ? "+1" : "+91",
              phoneNumber
            });
          }}
        >
          {options.map((option: { name: string; code: number }, index: any) => <option key={`option-v2-${index}`} value={option.name}>{option.code}</option>)}
        </select>
        <input
          type="number"
          id={`field-${name}`}
          className={`h-[56px] pl-28 pt-px flex items-center border w-full text-base text-gray-1010 leading-tight ${hasError ? "border-red-600" : "border-gray-1020"
          }`}
          placeholder={placeholder || label}
          disabled={disabled}
          maxLength={maxLength || 1000}
          onKeyDown={handleKeyPressForNumericInputs}
          onWheel={(e: WheelEvent<HTMLDivElement>) =>
            (e.target as HTMLTextAreaElement).blur()}
          {...input}
          onChange={(e) => {
            input.onChange({
              countryCode: selectedCountry === "us" ? "+1" : "+91",
              phoneNumber: e.target.value
            });
          }}
          value={phoneNumber}
        />
      </div>
      {icon && <div className="absolute top-0 right-0 mr-4 mt-4">{icon}</div>}
      {hasError && !meta.active && (
      <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {meta.active && helperText && (
      <p className="text-xs text-gray-200">{helperText}</p>
      )}
      <style jsx>
        {`
          input {
            transition: all 200ms ease-out;
            box-sizing: border-box;
          }
          input:focus {
            box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
              0px 0px 1px rgba(130, 136, 148, 0.16);
            border-color: #0da2f7;
          }
        `}
      </style>
    </div>
  );
};

export default PhoneInputWithFlagV2;
