import basePath from "lib/helpers/basePath";
import Image from "next/image";
import { FunctionComponent } from "react";

interface Props {
  text: string
}

const NoteCard: FunctionComponent<Props> = ({ text }) => (
  <div className="flex bg-[#E7F5F4] rounded-full pl-3 pr-6 w-fit py-3 items-center">
    <div className="flex items-center">
      <Image
        src={`${basePath}/assets/icons/star-icon-2.svg`}
        width={20}
        height={20}
        alt="star icon"
      />
    </div>
    <span className="pl-2 text-[#336664] text-[12px] leading-[17px]">{text}</span>
  </div>
);

export default NoteCard;
