import NoteCard from "components/common/NoteCard";
import GetCallbackForm from "components/LandingPage/GetCallbackForm";
import { flatRoiRefi } from "DataMapper/Refi/constants";
import Image from "next/image";
import React from "react";

function Jumbotron() {
  return (
    <section className="flex flex-col lg:flex-row mx-auto pt-[20px] pb-[40px] px-[16px] md:px-[5%] xl:px-0 md:py-[60px] justify-between max-w-[1200px]">
      <div className="lg:w-6/12">
        <NoteCard text="F1, H1B &amp; OPT Visa holders are also eligible" />
        <h1 className="text-[#1B1D6A] font-bold text-[28px] md:text-[46px] leading-[32px] md:leading-[55px] my-4">
          Reduce your student loan interest to {flatRoiRefi}%*
        </h1>
        <ul className="list-disc text-[#565660] md:text-[22px] text-[16px] leading-[30px] pl-4 md:pl-8 mb-2 md:mb-4">
          <li className="py-2">Get your loan refinanced in 3 days*</li>
          <li className="py-2">Save up to $20,000*&quot;</li>
          <li className="py-2">Successfully financed more than 2000 users</li>
        </ul>
        <div className="hidden lg:block">
          <GetCallbackForm />
        </div>
      </div>
      <div className="lg:w-6/12 flex items-center justify-center">
        <Image
          src="https://ik.imagekit.io/onsnhxjshmp/Leap_Finance/refi-banner-desktop_tbs7wftsQ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675866292750"
          width={527}
          height={478}
          alt="International student saving thousands of dollars by refinancing her education loan through a quick online process"
        />
      </div>
      <div className="visible lg:hidden">
        <GetCallbackForm />
      </div>
    </section>
  );
}

export default Jumbotron;
