import { NextPage } from "next";
import dynamic from "next/dynamic";
import Jumbotron from "components/LandingPage/Jumbotron";
import Layout from "components/common/Layout";
import { useEffect } from "react";
import { logPageView } from "components/Analytics/google-analytics";
import { trackPage } from "scripts/segment";
import { pageName } from "utils/events_utils";

const Callback = dynamic(() => import("components/LandingPage/Callback"));
const Eligibility = dynamic(() => import("components/LandingPage/Eligibility"));
const Faqs = dynamic(() => import("components/LandingPage/Faqs"));
const RefinanceSteps = dynamic(() => import("components/LandingPage/RefinanceSteps"));
const SupportersAndStats = dynamic(() => import("components/LandingPage/SupportersAndStats"));
const Testimonials = dynamic(() => import("components/LandingPage/Testimonials"));
const WhatSection = dynamic(() => import("components/LandingPage/WhatSection"));
const WhySection = dynamic(() => import("components/LandingPage/WhySection"));

interface Props {
  faq: Array<Record<string, string>>;
}
const HomePage: NextPage<Props> = ({ faq }): JSX.Element => {
  useEffect(() => {
    logPageView();
    trackPage(pageName.REFI_LANDING_PAGE);
  }, []);
  return (
    <Layout
      title="Hassle-free Student Loan Refinancing"
      isLandingPage
      showAvatar={false}
      faq={faq}
    >
      <Jumbotron />
      <WhatSection />
      <WhySection />
      <Eligibility />
      <RefinanceSteps />
      <Testimonials />
      <SupportersAndStats />
      <Faqs faq={faq} />
      <Callback />
    </Layout>
  );
};

export async function getServerSideProps() { // TODO: FIX THIS LATER
  const Axios = (await import("axios")).default;
  const props: any = {};
  try {
    const res = await Axios.get(
      `${process.env.KNOWLEDGE_BASE_URL}/faqs?slug_in=refinance_landing_page`
    );
    if (res?.data) {
      props.faq = res?.data[0].faq;
    }
  } catch (err) {
    console.error(err);
  }
  return { props };
}

export default HomePage;
